import { PaymentForm } from "../../components/Payments/PaymentForm";
import { useState, useEffect } from "react";
import { useImplicitAuth } from "../../azureLogin/useImplicitAuth";
import ImplicitAuthContext from "../../azureLogin/useImplicitAuth";
import PPHeader from "../../components/Payments/PPHeader/PPHeader";
import Footer from "../../components/cgfns/cgfnsFooter/cgfnsFooter";
import PayerOrdersView from "../../components/Payments/PayerOrdersView";
// import { protectedResources } from "../../azureLogin/msalPayerConfig";



const PaymentsHome = () => {
  const { fetchData, fetchOrdersData, jwt } = useImplicitAuth();
  const [message, setMessage] = useState("Loading...");
  const [order, setOrder] = useState({});
  const [totalOrders, setTotalOrders] = useState(0);
  // const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchOrdersCount = async () => {
      try {
        // Start loading
        setLoading(true);

        // Fetch data from API
        const response = await fetchOrdersData('/federated/Application/payer/me');

        console.log('Response:', response);
        
        // Check if response is successful
        if (!response || !response.data) {
          throw new Error("No data received from the API");
        }

        // Filter the data to get only those with lineBalance > 0
        const filteredData = response.data.filter(item => item.lineBalance > 0);

        // Update the totalOrders state with the length of the filtered data
        setTotalOrders(filteredData.length);

        console.log('Total number of orders:', filteredData.length);

        if (filteredData.length == 0) {
          setMessage("No orders found.");
        }

      } catch (error) {
        // Log the error and display a message
        console.error("Error fetching federated data:", error);
        setMessage("Token has expired.");
      } finally {
        // Stop loading
        setLoading(false);
      }
    };

    fetchOrdersCount();
  }, []); // Empty dependency array means it only runs once, like componentDidMount




  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const orderId = jwt()?.extension_OrderId;
      
        // Check if orderId exists
        if (!orderId) {
          setMessage("Token has expired.");
          return;
        }
      
        // Fetch data
        const response = await fetchData(`/federated/Application/${orderId}`);
      
        // Check if the response status is OK
        if (!response.ok) {
          throw new Error(`Failed to fetch order data. Status: ${response.status}`);
        }
      
        const order = await response.json();
      
        // Assuming lineBalance might be updated
        order.lineBalance = order.lineBalance;
      
        // Set the fetched order data
        setOrder(order);
        console.log("Order Record", order);
      
      } catch (error) {
        // Catch unexpected errors (e.g., network failure, invalid JSON)
        console.error("Error fetching federated data:", error);
        
        if (error.status === 401) {
          setMessage("Token has expired.");
        }
        setMessage("Token has expired.");

        
      }
    };
    
    fetchOrderData();
  }, []);  // Dependency array can be extended if needed (e.g., dependencies like jwt or fetchData)
    
  

  return (
    <>
      {
        totalOrders > 1 
          ? <PayerOrdersView /> 
          : (totalOrders === 1 && order 
              ? (
                <>
                  <PPHeader />
                  <PaymentForm order={order} />
                  <Footer />
                </>
              ) 
              : (
                <>
                  <PPHeader />
                  <h4 style={{ backgroundColor: "white", padding: "30px", height: 550, width: "100%" }}>{message}</h4>
                  <Footer />
                  </>
                  ))
      }
    </>
  );
  
  

};




const PaymentsSite = () => {

  return (
    <>

      <ImplicitAuthContext>
        <PaymentsHome />
      </ImplicitAuthContext>
    
    </>
  );
};

export default PaymentsSite;

