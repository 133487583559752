
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/cgfns.css';
import Header from '../../../components/cgfns/cgfnsHeader/cgfnsHeader';
import Footer from '../../../components/cgfns/cgfnsFooter/cgfnsFooter';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from "react";
import StatusTracker from '../../../components/cStatusTracker';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate } from '@azure/msal-react';

const CgfnsStatusTracker = () => {
    
  const { t, i18n } = useTranslation();
    return (
<><Header></Header>
<AuthenticatedTemplate>
<div className="container-custom-color-cgfns">
<StatusTracker isICD={false}></StatusTracker>
<div className="container text-center">
                <span className="text-light">
                  <a href='https://www.trumerit.org/privacy-policy/' className='footer-color' target='_blank' rel='noopener noreferrer'>
                    {t('Privacy_Policy')}</a>
                  <span className='footer-color'> | </span>
                  <a href='https://cgfns.my.site.com/applicantPortal/s/terms' className='footer-color' target='_blank' rel='noopener noreferrer'>
                    {t('Agree_to_t_and_c_2')}
                  </a>
                </span>
              </div>
    </div>
<Footer></Footer>
</AuthenticatedTemplate>
</>
    );
}


export default CgfnsStatusTracker;