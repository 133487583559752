import { useState, useEffect } from "react";
import * as React from 'react';
import styles from "./paymentForm.module.css";
import { useImplicitAuth, signOut } from "../../azureLogin/useImplicitAuth";
import OrderView from "../Payments/OrderView";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import valid from 'card-validator';
import Loader from '../loader';
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const PaymentForm = ({ order }) => {
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState(styles.messageok);
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState(null);
  const [isCardValid, setIsCardValid] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAmountZero, setIsAmountZero] = useState(false);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isRefundPolicyChecked, setIsRefundPolicyChecked] = useState(false);
  const [declineErrorMsg, setDeclineErrorMsg] = useState('');
  const [errorMessageClass, setErrorMessageClass] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [expiryDateError, setExpiryDateError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [countries, setCountries] = useState([]);
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddressError, setStreetAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');


  const { fetchData, jwtToken } = useImplicitAuth();
  const navigate = useNavigate();


  useEffect(() => {
    // This will trigger a re-render when the message state changes
    console.log("Message updated:", message);
  }, [message]);

  useEffect(() => {
    console.log("decline error message", declineErrorMsg);
  }, [declineErrorMsg]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetchData('/paypal/countries');
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setCountryError('Failed to load countries');
      }
    };

    fetchCountries();
  }, []);


  const validateName = (name, field, required = false) => {
    if (required && name.trim() === '') {
      return field === 'lastName' ? 'Please fill the last name' : '';
    }
    return '';
  };

  const resetForm = () => {
    setCardNumber('');
    setCardType(null);
    setIsCardValid(false);
    setExpiryDate('');
    setCvv('');
    setCity('');
    setStreetAddress('');
    setState('');
    setPostalCode('');
    setCountry('');
    setFirstName('');
    setLastName('');
    setErrors({});
    setMessage('');
    setMessageClass(styles.messageok);
    setIsRefundPolicyChecked(false);
    document.getElementById('form').reset();
  };

  useEffect(() => {
    if (order.lineBalance === 0) {
      setIsAmountZero(true);
      setMessage("No Due Amount");
      setMessageClass(styles.messageok);
    }
  }, [order.lineBalance]);

  const TIMEOUT_ERROR_MESSAGE = "We did not receive a response from the credit card payment processing system. Please try again later and contact TruMerit, Inc. if the problem persists.";
  const PAYMENT_FAILED_MESSAGE = "We are unable to process your payment. Please try again later, use another card, or contact TruMerit, Inc. for assistance.";
  const PAYMENT_DECLINED_MESSAGES = {
    2: "Invalid Credit Card Type. TruMerit, Inc. only accepts Visa, Mastercard, and Discover Credit Cards. Please enter the card number from one of these types.",
    12: "Your bank has declined approval of this payment. Please check the credit card number, expiration date, and contact information to make sure they were entered correctly. If this does not resolve the problem, please contact your card’s customer service department for assistance.",
    13: "This payment cannot be approved electronically but can be approved with a verbal authorization. Contact your merchant bank to obtain an authorization and instructions to complete the payment.",
    23: "Your credit card number is invalid. Check credit card number and re-submit.",
    24: "The expiration date is not valid. Please correct and resubmit.",
    112: "The address you have entered does not match the address associated with your credit card. Please correct the address and resubmit.",
    114: "The CVV Code you have entered is not valid. Please correct and resubmit the payment."
  };

  const showResponseModal = (responseDetails) => {
    const { success, name, orderId, amountPaid,  transactionId, remainingBalance, orderDate, message,statusCode,result } = responseDetails;

    // Default modal values
    let modalTitle = "";
    let modalMessage = "";
    let iconType = "error";
    let confirmColor = "#dc3545";

    if (success) {
      modalTitle = "Payment Successful";
      modalMessage = `
      <div class='text-left payment-block'>
        <h5>Payment Details:</h5>
        <p><strong>Name:</strong> ${name}</p>
        <p><strong>Order ID:</strong> ${orderId}</p>
        <p><strong>Order Date:</strong> ${orderDate}</p>
        <p><strong>Transaction ID:</strong> ${transactionId}</p>
        <p><strong>Amount Paid:</strong> $${amountPaid}</p>
        <p><strong>Remaining Balance Due:</strong> ${remainingBalance}</p>
        <p><strong>Status:</strong> Payment has been sucessfully processed</p>
      </div>`;
      iconType = "success";
      confirmColor = "#28a745";
    } else {
      // Error handling based on statusCode and message.result
      switch (statusCode) {
        case 408: // Timeout errors
          modalTitle = "Payment Failed";
          if ([104, 109, 115, 150].includes(result)) {
            modalMessage = TIMEOUT_ERROR_MESSAGE;
          }else{modalMessage = PAYMENT_FAILED_MESSAGE;}
          break;

        case 409: // Payment declined errors
          modalTitle = "Payment Declined";
          if (result !== undefined && PAYMENT_DECLINED_MESSAGES[result]) {
            modalMessage = PAYMENT_DECLINED_MESSAGES[result];
          }else{modalMessage = PAYMENT_FAILED_MESSAGE;}
          break;

        case 422: // Validation error
          modalTitle = "Data Error";
          modalMessage = message + "; Please fix the error and resubmit your request.";
          break;

        case 500: // System error
          modalTitle = "Payment Failed";
          modalMessage = PAYMENT_FAILED_MESSAGE;
          break;

        default: // Unknown error
          modalTitle = "Payment Failed";
          modalMessage = PAYMENT_FAILED_MESSAGE;
          break;
      }
    }

    // Display the modal with the appropriate message
    MySwal.fire({
      title: modalTitle,
      html: modalMessage,
      icon: iconType,
      confirmButtonText: "Close",
      confirmButtonColor: confirmColor,
      allowOutsideClick: false,
    }).then((result) => {
      if (success && result.isConfirmed) {
        setTimeout(() => signOut(), 3000);
      }
    });
  };


  const showDeclineConfirmModal = () => {
    MySwal.fire({
      title: "Refuse Payment",
      text: "Are you sure you want to refuse the payment? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, Refuse Payment",
      cancelButtonText: "No",
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetchData(`/paypal/declinePaymentRequest/${order.orderNumber}?payerEmail=${encodeURIComponent(jwtToken.authEmail)}`, {
            method: "GET",
          });

          // Only attempt to parse the response if it's not 204 No Content
          let responseData = null;
          if (response.ok && response.status !== 204) {
            responseData = await response.json();
          }

          console.log("decline response", response);

          if (!response.ok) {
            console.log("failed");
            // Safely handle API error
            const errormsg = responseData?.error?.message || "An unexpected issue occurred. Please try again later.";
            console.log(errormsg);
            setDeclineErrorMsg(`Failed to refuse payment: ${errormsg}`);
            setErrorMessageClass(styles.messageerror);
          } else {
            // Successfully declined payment
            setDeclineErrorMsg("Payment declined Successfully.");
            setErrorMessageClass(styles.messageerror);
          }
        } catch (error) {
          // Handle unexpected errors
          setDeclineErrorMsg(`An error occurred while refusing the payment: ${error.message}`);
          setErrorMessageClass(styles.messageerror);
        }
        
        setTimeout(() => signOut(), 5000);
      }
    });
  };


  const handleCardNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setCardNumber(input);

    // Clear the card number error if input is not empty
    if (input) {
      setCardNumberError('');
    }

    const numberValidation = valid.number(input);

    if (numberValidation.card) {
      const { type } = numberValidation.card;
      setCardType(type);
      setIsCardValid(numberValidation.isValid);

      // Check if the card type is valid
      const validCardTypes = ['visa', 'mastercard', 'discover'];
      if (!validCardTypes.includes(type)) {
        setErrors(prev => ({
          ...prev,
          cardNumber: 'Invalid tender type: TruMerit, Inc. only accepts Visa, Mastercard, or Discover Credit Cards. Please enter a valid Visa, Mastercard, or Discover Credit Card number.'
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          cardNumber: input.length !== 16 ? 'Card number must be 16 digits' : ''
        }));
      }
    } else {
      setCardType(null);
      setIsCardValid(false);
      setErrors(prev => ({
        ...prev,
        cardNumber: ''
      }));
    }
  };

  const handleExpiryDateChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    let formattedInput = input;
    if (input.length > 4) {
      input = input.slice(0, 6);
      formattedInput = input.slice(0, 4) + '/' + input.slice(4);
    } else if (input.length === 4) {
      formattedInput = input + '/';
    }
    setExpiryDate(formattedInput);
    let error = '';
    if (input.length === 6) {
      const year = parseInt(input.slice(0, 4));
      const month = parseInt(input.slice(4, 6));
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        error = 'Expiry date must be in the future';
      } else if (month > 12 || month === 0) {
        error = 'Invalid month';
      }
    }
    setErrors(prev => ({ ...prev, expiryDate: error }));
    if (expiryDate) {
      setExpiryDateError('');
    }
  };

  const handleCvvChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setCvv(input);
    if (input) {
      setCvvError('');
    }
  };

  const formatCardNumber = (number) => {
    const groups = number.match(/\d{1,4}/g) || [];
    return groups.join(' ');
  };

  const handleCityChange = (e) => {
    const input = e.target.value;
    setCity(input);
    if (input.trim() !== '') {
      setCityError('');
    }
  };

  //returns error message, or undefined if validation succeeds.
  function validate_stateProvince(paymentCountry, stateProvince) {
    let country = countries.find(c => c.countryName == paymentCountry);
    if (paymentCountry === 'United States') {
      if (stateProvince === '')
        return "Please enter the State or Province";
      if (stateProvince?.length != 2)
        return "Enter a valid 2-letter US state code (e.g., CA, NY).";
      if (country?.stateProvinces?.length && !country.stateProvinces.some(s => s.abbreviation == stateProvince)) {
        return "Invalid State or Province.";
      }
    }
    else if (stateProvince?.length > 30) {
      return "State or Province may not exceed 30 characters";
    }
  };

  const validateStateAndPostalCode = (_paymentCountry,_stateProvince,_postalCode) => {
    let isValid = true;

    // Validate stateProvince
    let errorMessage = validate_stateProvince(_paymentCountry,_stateProvince);
    if (errorMessage?.length > 0) {
      setStateError(errorMessage);
      isValid = false;
    } else {
      setStateError("");
    }

    // Validate postalCode for United States
    if (_paymentCountry === 'United States') {
      const regex = /^\d{5}(-\d{4})?$/;

      if (_postalCode.trim() === "") {
        setPostalCodeError("Please enter your Postal Code");
        isValid = false;
      } else if (!regex.test(_postalCode)) {
        setPostalCodeError("Use postal code format XXXXX or XXXXX-XXXX.");
        isValid = false;
      } else {
        setPostalCodeError("");
      }
    } else {
      setPostalCodeError("");
    }

    return isValid;
  };

  const handleStateChange = (e) => {
    const input = e.target.value.trim();
    const formattedInput = country === "United States" ? input.toUpperCase() : input;
    setState(formattedInput);

    validateStateAndPostalCode(country,formattedInput,postalCode);
  };

  const handlePostalCodeChange = (e) => {
    let input = e.target.value;
    let formattedInput = input.replace(/\D/g, '');

    if (country === 'United States') {
      if (formattedInput.length <= 5) {
        formattedInput = formattedInput.slice(0, 5);
      } else {
        const firstPart = formattedInput.slice(0, 5);
        const secondPart = formattedInput.slice(5, 9);
        formattedInput = `${firstPart}-${secondPart}`;
      }


    } else {
      formattedInput = input.replace(/[^A-Za-z0-9-\s]/g, '');
    }

    setPostalCode(formattedInput);

    validateStateAndPostalCode(country,state,formattedInput);
  };


  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value.trim();
    setCountry(selectedCountry);

    if (!selectedCountry || selectedCountry.length < 1) {
      setCountryError("Please select your country.");
    } else {
      setCountryError("");
    }

    if (selectedCountry !== 'United States') {
      setStateError(""); // Clear state error if country is not the United States
    }

    // Re-validate stateProvince and postalCode after changing the country
    validateStateAndPostalCode(selectedCountry,state,postalCode);
  };



  const handleFirstNameChange = (e) => {
    const input = e.target.value;
    setFirstName(input);
    setErrors(prev => ({
      ...prev,
      firstName: validateName(input, 'firstName'),
    }));
  };

  // Last name change handler
  const handleLastNameChange = (e) => {
    const input = e.target.value;
    setLastName(input);
    setErrors(prev => ({
      ...prev,
      lastName: validateName(input, 'lastName', true),
    }));
  };

  const handleStreetAddressChange = (e) => {
    const input = e.target.value;
    setStreetAddress(input);
    if (input.trim() !== '') {
      setStreetAddressError('');
    }
  };


  const submitHandler = async (e) => {
    let isValid = true;
    e.preventDefault();
    setMessage("");

    if (!cardNumber || (isCardValid && cardNumber.length !== 16)) {
      const validCardTypes = ['visa', 'mastercard', 'discover'];
      if (validCardTypes.includes(cardType) && cardNumber.length !== 16) {
        setCardNumberError('Card number must be 16 digits.');
        isValid = false;
      } else if (!cardNumber) {
        setCardNumberError('Please enter your Card Number');
        isValid = false;
      }
    } else if (!isCardValid) {
      setCardNumberError('Card number is not valid.');
      isValid = false;
    } else {
      setCardNumberError('');
    }

    if (!expiryDate) {
      setExpiryDateError('Please enter your Expiry Date.');
      isValid = false;
    } else if (expiryDate.length < 7) {
      setExpiryDateError('Expiry date must be in YYYY-MM format');
      isValid = false;
    }else if (errors.expiryDate) { 
      isValid = false;
    } else {
      setExpiryDateError('');
    }

    if (!cvv) {
      setCvvError('Please enter your CVV.');
      isValid = false;
    } else if (cvv.length !== 3) {
      setCvvError('CVV must be 3 digits');
      isValid = false;
    } else {
      setCvvError('');
    }

    if (!city || city.trim() === '') {
      setCityError('Please enter your City.');
      isValid = false;
    }

    if( !validateStateAndPostalCode(country,state,postalCode))
      {
        isValid = false;
      }


    if (errors?.firstName) {
      isValid = false;
    }

    if (!lastName.trim()) {
      setErrors(prev => ({
        ...prev,
        lastName: 'Please enter your Last Name.',
      }));
      isValid = false;
    } else if (errors?.lastName) {
      isValid = false;
    }

    if (!streetAddress || streetAddress.trim() === '') {
      setStreetAddressError('Please enter your Street Address.');
      isValid = false;
    }

    if (!country.trim() || country.length < 1) {
      setCountryError("Please select your Country.");
      isValid = false;
    } else {
      setCountryError("");
    }

    if (!isRefundPolicyChecked) {
      setMessage("Acknowledgement of No Refund statement required.");
      setMessageClass(styles.messageerror);
      isValid = false;
    }

    if (!isValid) {
      return;
    }



    setIsLoading(true);

    try {
      const form = e.target;
      const formData = new FormData(form);
      const formObj = Object.fromEntries(formData.entries());

      // Format credit card number and expiration date
      formObj.creditCardNbr = formObj.creditCardNbr.replace(/\s/g, '');
      formObj.expirationDate = `${formObj.expirationDate.slice(0, 4)}-${formObj.expirationDate.slice(5)}-01`;
      formObj.customersId = order.customersId;
      formObj.orderHeadersId = order.orderHeadersId;
      formObj.trxAmt = order.lineBalance;

      // API call
      const response = await fetchData(`/payflow/charge/${order.orderNumber}`, {
        method: "POST",
        body: JSON.stringify(formObj)
      });

      const responseData = await response.json();

      // Handle response
      if (![200, 408, 409].includes(response.status)) {
        console.error('Payment Error:', responseData);
        setMessageClass(styles.messageerror);
        const { statusCode,  details } = responseData.error;
        const errorDetails = details?.map((error) => `${error.path}: ${error.message}`).join(', ');
        // Show the modal even for errors to inform the user
        showResponseModal({
          success: false,
          name: `${formObj.firstName} ${formObj.lastName}`,
          orderId: order.orderNumber,
          amountPaid: order.lineBalance,
          transactionId: 'N/A',
          remainingBalance: 'N/A',
          orderDate: new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }),
          message: errorDetails,
          statusCode:statusCode,
        });

      } else {
        console.log('Payment Response:', responseData);
        const { message, result, pnref, bal, amt } = responseData?.message?.message ? responseData.message : responseData;


        setMessageClass(result === 0 ? styles.messageok : styles.messageerror);

        // Show the modal for successful payment
        showResponseModal({
          success: result === 0,
          name: `${formObj.firstName} ${formObj.lastName}`,
          orderId: order.orderNumber,
          amountPaid: amt,
          transactionId: pnref,
          remainingBalance: bal,
          orderDate: new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }),
          result: result,
          statusCode:response.status,
        });
      }
    } catch (error) {
      console.error('Payment Processing Error:', error);

      setMessageClass(styles.messageerror);
      let  responseData ={};
      responseData.error=error;
      // Show a modal for unexpected errors
      showResponseModal({
        success: false,
        name: 'N/A',
        orderId: 'N/A',
        amountPaid: 'N/A' ,
        transactionId: 'N/A',
        remainingBalance: 'N/A',
        orderDate: new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        }),
        message: PAYMENT_FAILED_MESSAGE
      });
    } finally {
      setIsLoading(false);
    }
  };

  const SubmitPayment = () => (
    <>
      <button type="submit" className={styles.button + " btn btn-primary"} disabled={isLoading || isAmountZero}>
        {isLoading ? 'Processing...' : 'Pay'}
      </button>

      <button type="button" onClick={showDeclineConfirmModal} className={styles.button + " btn btn-danger"}>
        Refuse Payment
      </button>
    </>
  );

  const Message = ({ content, className }) => <p className={className}>{content}</p>;

  return (
    <div className={`${styles.form} payment-section`}>
      {isAmountZero ? (
        <p className={messageClass}>{message}</p>
      ) : (
        <>
          <OrderView order={order} />
          <hr className={styles.divider} />

          <h3 className={styles.heading}>Card Details</h3>
          <form id="form" className="form" onSubmit={submitHandler}>
            <div className="row">
              <div className="col-md-12">
                <div className={`form-group ${cardNumberError ? 'errorField' : ''}`}>
                  <input
                    name="creditCardNbr"
                    type="text"
                    placeholder="Card Number"
                    className={`${styles.input} form-contorl`}
                    value={formatCardNumber(cardNumber)}
                    onChange={handleCardNumberChange}
                    maxLength="19"
                  />
                  {errors.cardNumber && <p className="small text-danger">{errors.cardNumber}</p>}
                  {cardType && (
                    <p className="small text-dark">
                      Card Type: {cardType.charAt(0).toUpperCase() + cardType.slice(1)}
                    </p>
                  )}

                  {cardNumberError && <p className="small text-danger">{cardNumberError}</p>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className={`form-group ${expiryDateError ? 'errorField' : ''}`}>
                  <input
                    name="expirationDate"
                    type="text"
                    placeholder="Expiration Date: YYYY/MM"
                    className={`${styles.input} form-control`}
                    value={expiryDate}
                    onChange={handleExpiryDateChange}
                    maxLength="7"
                  />
                  {errors.expiryDate && <div className="small text-danger">{errors.expiryDate}</div>}
                  {expiryDateError && <p className="small text-danger">{expiryDateError}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className={`form-group ${cvvError ? 'errorField' : ''}`}>
                  <input
                    name="securityCode"
                    maxLength={3}
                    type="text"
                    placeholder="CVV"
                    className={styles.input}
                    value={cvv}
                    onChange={handleCvvChange}
                  />
                  {cvvError && <p className="small text-danger">{cvvError}</p>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    name="firstName"
                    type="text"
                    placeholder="First Name on Card"
                    className={styles.input}
                    value={firstName}
                    onChange={handleFirstNameChange}
                    maxLength="30"
                    style={{ marginBottom: '10px' }}
                  />
                  {errors.firstName && <p className="small text-danger">{errors.firstName}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <input
                    name="lastName"
                    type="text"
                    placeholder="Last Name on Card"
                    className={styles.input}
                    value={lastName}
                    onChange={handleLastNameChange}
                    maxLength="30"
                  />
                  {errors.lastName && <p className="small text-danger">{errors.lastName}</p>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <input
                  name="streetAddress1"
                  type="text"
                  placeholder="Street Address"
                  className={styles.input}
                  value={streetAddress}
                  onChange={handleStreetAddressChange}
                  maxLength="30"
                />
                {streetAddressError && <p className="small text-danger">{streetAddressError}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <select
                  name="country"
                  className={styles.input}
                  value={country}
                  onChange={handleCountryChange}
                >
                  <option value="">Select a Country</option>
                  {countries.map((countryItem) => (
                    <option key={countryItem.paypalCountriesId} value={countryItem.countryName}>
                      {countryItem.countryName}
                    </option>
                  ))}
                </select>
                {errors.country && <p className="small text-danger">{errors.country}</p>}
                {countryError && <p className="small text-danger">{countryError}</p>}
              </div>

              <div className="col-md-6">
                <input
                  name="city"
                  type="text"
                  placeholder="City"
                  className={styles.input}
                  value={city}
                  onChange={handleCityChange}
                  maxLength="30"
                />
                {cityError && <p className="small text-danger">{cityError}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <input
                  name="stateProvince"
                  type="text"
                  placeholder="State/Province"
                  className={styles.input}
                  value={state}
                  onChange={handleStateChange}
                  maxLength="30"
                />
                {stateError && <p className="small text-danger">{stateError}</p>}
              </div>

              <div className="col-md-6">
                <input
                  name="postalCode"
                  type="text"
                  placeholder="Postal Code"
                  className={styles.input}
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                  maxLength={country === 'United States' ? 10 : 30}
                />
                {country === 'United States' && postalCodeError && (
                  <p className="small text-danger">{postalCodeError}</p>
                )}
              </div>

              <div className="form-group refund-policy" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id="refundPolicy"
                  checked={isRefundPolicyChecked}
                  onChange={() => setIsRefundPolicyChecked(!isRefundPolicyChecked)}
                  className="custom-checkbox"
                  style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <label htmlFor="refundPolicy" className="small" style={{ color: isRefundPolicyChecked ? 'red' : 'red' }}>
                  <span className="agree-text">I Agree</span> - By checking this checkbox, I agree and understand that TruMerit, Inc. has a NO REFUND policy. No refund will be given after the payment is made.
                </label>
              </div>
            </div>

            <div style={{ marginTop: '30px' }} className="button-group">
              <SubmitPayment />
            </div>
          </form>

          {message && <Message content={message} className={messageClass} />}
          {declineErrorMsg && <Message content={declineErrorMsg} className={errorMessageClass} />}
          {isLoading && <Loader visible={true} />}
        </>
      )}
    </div>
  );
};
