import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/cgfns.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 nav-background-color-cgfns">
      <div>
      <p style={{textAlign: "center"}}><strong><a style={{color: "white"}} href="https://www.trumerit.org/privacy-policy/" target='_blank'>Privacy Policy</a></strong><br />
      © 2025 <a style={{color: "white"}} href="https://www.trumerit.org/" target='_blank'>TruMerit Inc.</a> All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
