import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/css/cgfns.css" 
import logo from '../../../assets/image/TruMerit_CGFNS_Message-Reverse.png';
// import { Button } from 'react-bootstrap';
// import useAuth from '../../../azureLogin/useAuth';

const PPHeader = () => {
    // const { logout } = useAuth();
    const [hover, setHover] = useState(false);

    let hashParams = new URLSearchParams(window.location.href.slice(window.location.href.indexOf('#') + 1));
    let access_token = hashParams.get("access_token");

    return (
        <nav className="navbar navbar-expand-lg navbar-custom-cgfns">
            <div className="container-fluid">
                <a className="navbar-brand">
                    <img src={logo} alt="CGFNS Logo" width="240" />
                </a>
                

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav m-auto">            
            <li className="nav-item-pp">
            <a href={`/payments#access_token=${access_token}`} variant="a" className="nav-link">{('Payment Requests')}</a>
            </li>   
            <li className="nav-item-pp">
              <a href={`/paymentportal/paidorders#access_token=${access_token}`} variant="a" className="nav-link">{('Paid Orders')}</a>
            </li> 
          </ul>
            </div>
            {/* <div className="d-flex">
                    <Button
                        className={`logout-button ${hover ? 'hover' : ''}`}
                        variant="outline-primary"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </div> */}
            </div>
        </nav>
    );
};

export default PPHeader;