import React, { useState, useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import useAuth from "../../azureLogin/useAuth";
import { protectedResources } from "../../azureLogin/msalPayerConfig"
import { DataGrid } from '@mui/x-data-grid';
import PPHeader from "./PPHeader/PPHeader";
import Footer from "../cgfns/cgfnsFooter/cgfnsFooter";
import { useImplicitAuth } from "../../azureLogin/useImplicitAuth";
import ImplicitAuthContext from "../../azureLogin/useImplicitAuth";


function formatUSD(val) {
  val ||= 0;
  const s = Math.round(val * 100).toString();
  if (s.length == 1)
    return `$0.0${s}`;
  else if (s.length == 2) {
    return `$0.${s}`;
  }
  const e = s.length - 3;
  let n = "." + s.slice(-2);
  for (let i = 0; i <= e; i++) {
    if (i && (i % 3 == 0))
      n = ',' + n;
    n = s[e - i] + n;
  }
  return '$' + n;
}

function getRowId(row) {
  return row.Id;
}

const PaidOrdersView = () => {
  const [orders, setOrders] = useState([]);
  // const { fetchData } = useAuth();
  const { fetchOrdersData } = useImplicitAuth() || {};
  const [noPaidHistory,setNoPaidHistory] = useState(false);

  useEffect(() => {
    // Get the date for one year ago from today.
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    fetchOrdersData(protectedResources.apiPayer.queries.paidorders)
      .then(response => {
        // Filter for line balance = 0 & last one year paid orders.
        const filteredOrders = response.data.filter(order => {
          const orderDate = new Date(order.Application.orderDate);
          return orderDate >= oneYearAgo;
        });
        setOrders(filteredOrders);
        setNoPaidHistory(filteredOrders.length===0);
        console.log("Filtered Orders (Past Year)", filteredOrders);
      })
      .catch(ex => {
        console.log("Error fetching orders", ex);
      });
  }, []);


 const columns = [
  { field: 'orderNumber', headerName: 'Order Number', width: 120, renderHeader: () => <strong>Order Number</strong>, valueGetter: (value, row) => row.Application?.orderNumber },
  { field: "orderDate", headerName: 'Date', width: 100, renderHeader: () => <strong>Date</strong>, type: 'date', valueGetter: (value, row) => new Date(row.Application?.orderDate) },
  { field: 'fullName', headerName: 'Applicant Name', width: 180, renderHeader: () => <strong>Applicant Name</strong>, valueGetter: (value, row) => `${row.Application?.Contact?.FirstName || ''} ${row.Application?.Contact?.LastName || ''}` },
  { field: 'orderType', headerName: 'Description', flex: 1, renderHeader: () => <strong>Description</strong>, valueGetter: (value, row) => row.Application?.orderType },
  { field: 'amount', headerName: 'Amount', width: 100, renderHeader: () => <strong>Amount</strong>, valueGetter: (value, row) => formatUSD(row.amount) },
  { field: 'transactionId', headerName: 'Transaction Id', width: 180, renderHeader: () => <strong>Transaction Id</strong>, valueGetter: (value, row) => row.transactionId || 'N/A' },
];

  return (
    <>
      <PPHeader />
      <Container className="mt-3">
        <Card className="shadow-sm mb-4">
          <Card.Body>
            <h2 className="text-center mb-4 text-primary">Paid Orders</h2>
            <p className="text-center text-muted">
              Below is a list of orders you have paid for over the past year. You can view the transaction details below.
            </p>
            <div style={{ height: 400, width: '100%' }}>
                { noPaidHistory ? ( <p style={{ textAlign: 'center', fontSize: '1.2rem', color: '#6c757d' }}>
                 No paid orders have been recorded in the past year. </p> ) : <DataGrid
                  columns={columns}
                  rows={orders}
                  getRowId={getRowId}
                  pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 15, page: 0 },
                    },
                  }}
                />}
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </>
  );
};


const PaidOrders = () => {

  return (
    <>

      <ImplicitAuthContext>
        <PaidOrdersView />
      </ImplicitAuthContext>
    
    </>
  );
};

export default PaidOrders;