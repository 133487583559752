import React, { useState, useEffect } from "react";
// import useAuth from "../../azureLogin/useAuth";
import { protectedResources } from "../../azureLogin/msalPayerConfig";
import PPHeader from "./PPHeader/PPHeader";
import { PayerForm } from "./PayerForm.js";
import "../Payments/paymentForm.module.css";
import ConfirmPaymentDetails from "./ConfirmPaymentDetails.js";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../cgfns/cgfnsFooter/cgfnsFooter.js";
import { useImplicitAuth } from "../../azureLogin/useImplicitAuth.js";

function formatUSD(val) {
  val ||= 0;
  const s = Math.round(val * 100).toString();
  if (s.length === 1) return `$0.0${s}`;
  if (s.length === 2) return `$0.${s}`;
  const e = s.length - 3;
  let formatted = "." + s.slice(-2);
  for (let i = 0; i <= e; i++) {
    if (i && i % 3 === 0) formatted = "," + formatted;
    formatted = s[e - i] + formatted;
  }
  return "$" + formatted;
}

function PaymentTable() {
  const { fetchOrdersData } = useImplicitAuth();
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [rowToRemove, setRowToRemove] = useState(null);
  const [showPayerForm, setPayerForm] = useState(false);
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(false);
  const [thirdPartyPayerEmail, setThirdPartyPayerEmail] = useState("");
  const [isPayAPICalled, setIsPayAPICalled] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  

   
  useEffect(() => {
    // Check if all rows are selected
    if (selectedRows.length === tableData.length && tableData.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedRows, tableData]);
  
  useEffect(() => {
    fetchOrdersData(protectedResources.apiPayer.queries.orders)
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => item.lineBalance > 0 
        );

        const fetchedData = filteredData.map((item, index) => ({
          id: index + 1,
          name: `${item.Contact.FirstName || ""} ${item.Contact.LastName || ""}`,
          cgfnsId: item.Contact.Applicant_ID__c || "N/A",
          order: item.orderNumber,
          orderName: item.orderType,
          price: formatUSD(item.lineBalance),
          orderHeadersId : item.orderHeadersId,
          email: item.ThirdPartyPayerEmail
        }));
        setTableData(fetchedData);
        setFilteredData(fetchedData);

        if (response.data[0]?.ThirdPartyPayerEmail) {
          setThirdPartyPayerEmail(response.data[0].ThirdPartyPayerEmail);
        }
      })
      .catch((err) => {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders. Please try again later.");
      });
  }, [paymentSuccess]);
 
  const columns = [
    { field: "name", headerName: "Applicant Name",  flex: 1, renderHeader: () => <strong>Applicant name</strong> },
    { field: "cgfnsId", headerName: "CGFNS ID#", width: 160, renderHeader: () => <strong>Applicant ID#</strong> },
    { field: "order", headerName: "Order #",width: 160, renderHeader: () => <strong>Order #</strong> },
    { field: "orderName", headerName: "Program", flex: 1 , renderHeader: () => <strong>Program</strong>},
    { field: "price", headerName: "Amount Due", width: 120 , renderHeader: () => <strong>Amount due</strong>,headerAlign: "right",align: "right"},
    { field: "actions", headerName: "Actions", width: 240 ,renderHeader: () => <strong>Refuse payment</strong>, renderCell: (params) => (
        <a href="#"onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleRemoveClick(params.row.id); }} className="remove-link">Refuse Payment Request</a>
      ),},
  ];

  useEffect(() => {
    const lowerSearchText = searchText.toLowerCase();
    setFilteredData(
      tableData.filter((row) => {
        const name = row.name || "";
        const program = row.orderName || "";
        const order = row.order || "";
        const cgfnsId = row.cgfnsId || "";
        return (
          name.toLowerCase().includes(lowerSearchText) ||
          program.toLowerCase().includes(lowerSearchText) ||
          order.toLowerCase().includes(lowerSearchText) ||
          cgfnsId.toLowerCase().includes(lowerSearchText)
        );
      })
    );
  }, [searchText, tableData]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleRemoveClick = (id) => {
    setRowToRemove(id);
    setShowPopup(true);
  };

  const handleConfirmRemove = () => {
    // Find the order number of the row to remove
    const rowToRemoveData = tableData.find((row) => row.id === rowToRemove);

    if (rowToRemoveData) {
      const orderId = rowToRemoveData.order;
      const email = rowToRemoveData.email;

      const response =  fetchOrdersData(`${protectedResources.apiPayer.queries.declinepayment}${orderId}?payerEmail=${encodeURIComponent(email)}`, {
        method: "GET",
      }
        )

        .then((response) => {
          if (response.status!=204) {
            throw new Error(`Failed to decline payment request for order: ${orderId}`);
          }
          return response;
        })
        .then((data) => {

          // Update the table data after successful API call
          setTableData(tableData.filter((row) => row.id !== rowToRemove));
          setSelectedRows(selectedRows.filter((rowId) => rowId !== rowToRemove));
          setShowPopup(false);
          setRowToRemove(null);
        })
        .catch((error) => {
          console.error("Error declining payment request:", error);
        });
    } else {
      console.error("Row to remove not found.");
    }
  };

  const handleCancelRemove = () => {
    setShowPopup(false);
    setRowToRemove(null);
  };

  const handleProceedToPayment = () => {
    const selectedData = tableData
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => ({
        orderNumber: row.order,
        firstName: row.name.split(" ")[0] || "",
        lastName: row.name.split(" ")[1] || "",
        program: row.orderName,
        lineBalance: row.price,
        orderHeadersId : row.orderHeadersId
      }));
    setSelectedPayments(selectedData);
    setPayerForm(false);
    setShowPaymentConfirm(true);
    setIsPayAPICalled(false);
   // setPaymentHeaderHidden(false);
  };

  const calculateTotalAmount = () => {
    return selectedRows
      .map((id) => {
        const row = tableData.find((row) => row.id === id);
        return row ? parseFloat(row.price.replace(/[$,]/g, "")) : 0;
      })
      .reduce((sum, value) => sum + value, 0);
  };

  const handleClosePopup = () => {
    setShowPaymentConfirm(false);
    setPaymentSuccess(true); // Trigger re-fetch of orders
  };

  const handlePayClick = () => {
    setShowPaymentConfirm(false);
    setPayerForm(true);
  };

  const handlePaySubmitClick = () => {

    setTableData(tableData => tableData.filter(row => 
      !selectedPayments.some(pay => pay.orderHeadersId === row.orderHeadersId && pay.StatusCode > 0)
    ));
 
      // setSelectedRows([]);

    setShowPaymentConfirm(true);
    setPayerForm(false);
    setIsPayAPICalled(true);
  };

  const handlePayCancelClick = () => {
    setShowPaymentConfirm(false);
    setPayerForm(false);
    setIsPayAPICalled(false);
  };

  return (
    <>
      <PPHeader />
      <div className="payment-container">
        {error && <div className="error-message">{error}</div>}

        {!showPayerForm  &&<div className="payment-header">
          <h5>Payment Requests for {thirdPartyPayerEmail && `${thirdPartyPayerEmail}`}</h5>
          <p>
            Please select the record(s) you would like to submit payment for and proceed to payment.
            If you select more than one record you can pay multiple accounts at one time.
          </p>
          <p>
            If you feel that you have been requested to make this payment in error you can select
            <strong> Refuse Payment Request </strong> and the applicant will be notified to submit payment themselves.
          </p>
        </div>}
        {!showPayerForm && (
          <>
          <div className="search-container">
              <input
                type="text"
                placeholder="Search by Applicant Name,Applicant ID#, Order# or Program"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input"
              />
            </div>
          <DataGrid
              rows={filteredData}
              columns={columns}
              checkboxSelection
              selectionModel={selectedRows}
              disableRowSelectionOnClick
              onRowSelectionModelChange ={(newSelection) => setSelectedRows(newSelection)}
              pagination 
              pageSizeOptions={[10, 15, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 15, page: 0 },
                },
              }}
            />
            <div className="payment-summary-container">
                 
                  <div   className="total-label min-padding-right">
                    Total Amount:
                  </div>
                  <div className="total-label min-padding-right ">{formatUSD(calculateTotalAmount())}</div>
                  <div className="min-padding-right">
                    <button
                      className={`sp-button sp-pay-button  ${selectedRows.length < 1 ? "disabled" : ""}`}
                      onClick={handleProceedToPayment}
                      disabled={selectedRows.length < 1}
                    >
                      Proceed to Payment
                    </button>
                  </div>
                 
               
            </div>
            {/* <div className="normal-red">Unpaid orders stay active for 90 days and will expire afterwards</div> */}
          </>
        )}
        {showPayerForm && selectedPayments.length > 0 && (          
          <PayerForm
            handlePaySubmitClick={handlePaySubmitClick} handlePayCancelClick={handlePayCancelClick}
            orders={selectedPayments} email={thirdPartyPayerEmail} toBePaid={formatUSD(calculateTotalAmount())}
          /> 
        )}
        {showPopup && (
          <>
            <div className="popup-overlay" onClick={handleCancelRemove}></div>
            <div className="popup">
              <p className="popup-message">Are you sure you would like to refuse this request for payment?</p>
              <p className="popup-submessage">By selecting <b>'Confirm'</b>, the applicant will be removed and will receive an email with a request to pay for their order.</p>
              <div className="popup-actions">
                <button onClick={handleCancelRemove} className="cancel-button">
                  Cancel
                </button>
                <button onClick={handleConfirmRemove} className="confirm-button">
                  Confirm
                </button>
              </div>
            </div>
          </>
        )}

        {showPaymentConfirm && (
          <ConfirmPaymentDetails
            selectedPayments={selectedPayments}
            isPayAPICalled={isPayAPICalled}
            onClose={handleClosePopup}
            onPayClick={handlePayClick}
            toBePaid={formatUSD(calculateTotalAmount())}
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default PaymentTable;