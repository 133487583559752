import { LogLevel } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: `${process.env.REACT_APP_PAYMENTS_SIGNUP_SIGNIN}`,
    forgotPassword: `${process.env.REACT_APP_PAYMENTS_FORGOT_PASSWORD}` ,
    editProfile: `${process.env.REACT_APP_PAYMENTS_EDIT_PROFILE}`,
  },
  authorities: {
    signUpSignIn: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_PAYMENTS_SIGNUP_SIGNIN}`,
    },
    forgotPassword: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_PAYMENTS_FORGOT_PASSWORD}`,
    },
    editProfile: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_PAYMENTS_EDIT_PROFILE}`,
    }
  },
  authorityDomain: `${process.env.REACT_APP_AUTHORITY_DOMAIN}`,
};

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_PAYMENTS_CLIENT_ID}`,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri:window.location.origin  + "/paymentportal",
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  interactionType: InteractionType.Redirect,
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error: console.error(message); break;
          case LogLevel.Info: console.info(message); break;
          case LogLevel.Verbose: console.debug(message); break;
          case LogLevel.Warning: console.warn(message); break;
        }
      },
    },
    allowNativeBroker: false,
  },
};


class ApiPayerResources {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.queries = new ApiPayerQueries(this);
    this.scopes = {
      me: [`${process.env.REACT_APP_PAYER_SCOPE}`],
    };
  }
}

class ApiPayerQueries {
  constructor(rsrc) {
    this.rsrc = rsrc;
  }

  get orders() {
    return `${this.rsrc.endpoint}/federated/Application/payer/me`;
  }

  get countries() {
    return `${this.rsrc.endpoint}/paypal/countries`;
  }
  
  get paidorders() {
    return `${this.rsrc.endpoint}/federated/Payment/payer/me`;
  }
  get postorders() {
    return `${this.rsrc.endpoint}/payflow/charge/`;
  }
  get declinepayment() {
    return `${this.rsrc.endpoint}/paypal/declinePaymentRequest/`;
  }
}

export const protectedResources = {
  apiPayer: new ApiPayerResources(process.env.REACT_APP_WEBSITE_NAMES),
};



export const loginRequest = {
  scopes: [...protectedResources.apiPayer.scopes.me],
  extraQueryParameters: {},
};

const urlParams = new URLSearchParams(window.location.search);
const impersonate = urlParams.get('impersonate');
if (impersonate) {
  loginRequest.extraQueryParameters.impersonate = encodeURIComponent(impersonate);
}